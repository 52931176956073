// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "plv-theme-material.scss";

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@import "plv-theme.scss";
@import "plv-icons.scss";
@import "social-network.scss";

// Data Tables Theming. For more info, please see:
// https://datatables.net/manual/styling
@import "dt-table-theme.scss";

/** Ionic CSS Variables **/
:root {
  /** Colors **/
  --ion-color-primary: #C23B33;
  --ion-color-primary-rgb: 194, 59, 51;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #ab342d;
  --ion-color-primary-tint: #c84f47;

  --ion-color-secondary: #B74849;
  --ion-color-secondary-rgb: 183, 72, 73;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #a13f40;
  --ion-color-secondary-tint: #be5a5b;

  --ion-color-tertiary: #8E2A2A;
  --ion-color-tertiary-rgb: 142, 42, 42;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #7d2525;
  --ion-color-tertiary-tint: #993f3f;

  --ion-color-success: #3D915E;
  --ion-color-success-rgb: 61, 145, 94;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #368053;
  --ion-color-success-tint: #509c6e;

  --ion-color-warning: #DF6744;
  --ion-color-warning-rgb: 223, 103, 68;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c45b3c;
  --ion-color-warning-tint: #e27657;

  --ion-color-danger: #8E1230;
  --ion-color-danger-rgb: 142, 18, 48;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #7d102a;
  --ion-color-danger-tint: #992a45;

  --ion-color-medium: #B0B2B3;
  --ion-color-medium-rgb: 176, 178, 179;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #9b9d9e;
  --ion-color-medium-tint: #b8babb;

  --ion-color-light: #E9F1F3;
  --ion-color-light-rgb: 233, 241, 243;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #cdd4d6;
  --ion-color-light-tint: #ebf2f4;

  --ion-color-dark: #2C343C;
  --ion-color-dark-rgb: 44, 52, 60;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #272e35;
  --ion-color-dark-tint: #414850;

}

/*
   * Standard Light Theme
   * -------------------------------------------
   */

body {
  --ion-background-color: #FFFFFF;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #2C343C;
  --ion-text-color-rgb: 44, 52, 60;

  --ion-color-step-50: #f4f5f5;
  --ion-color-step-100: #eaebec;
  --ion-color-step-150: #dfe1e2;
  --ion-color-step-200: #d5d6d8;
  --ion-color-step-250: #caccce;
  --ion-color-step-300: #c0c2c5;
  --ion-color-step-350: #b5b8bb;
  --ion-color-step-400: #abaeb1;
  --ion-color-step-450: #a0a4a7;
  --ion-color-step-500: #969a9e;
  --ion-color-step-550: #8b8f94;
  --ion-color-step-600: #80858a;
  --ion-color-step-650: #767b80;
  --ion-color-step-700: #6b7177;
  --ion-color-step-750: #61676d;
  --ion-color-step-800: #565d63;
  --ion-color-step-850: #4c5259;
  --ion-color-step-900: #414850;
  --ion-color-step-950: #373e46;

  --ion-item-background: #FFFFFF;
  --ion-toolbar-background: #FFFFFF;
  --ion-tab-bar-background: #FFFFFF;
  --ion-card-background: #FFFFFF;

  --ion-toolbar-border-color: var(--ion-color-step-250);
}