/* ******************* Accessibility ******************* */

@mixin font-sizes($size) {
  &.size-xsmall {
    font-size: $size - 0.2rem;
    line-height: $size + 0.4rem;
  }

  &.size-small {
    font-size: $size - 0.1rem;
    line-height: $size + 0.5rem;
  }

  &.size-medium {
    font-size: $size;
    line-height: $size + 0.6rem;
  }

  &.size-large {
    font-size: $size + 0.1rem;
    line-height: $size + 0.7rem;
  }

  &.size-xlarge {
    font-size: $size + 0.2rem;
    line-height: $size + 0.8rem;
  }
}

@mixin font-sizes-dynamic-text($size-h1, $size-p, $size-li) {
  &.size-xsmall {
    p {
      font-size: $size-p - 0.2rem;
      line-height: $size-p + 0.4rem;
    }

    h1 {
      font-size: $size-h1 - 0.2rem;
      line-height: $size-h1 + 0.4rem;
    }

    ul li,
    td,
    th {
      font-size: $size-li - 0.2rem;
      line-height: $size-li + 0.4rem;
    }
  }

  &.size-small {
    p {
      font-size: $size-p - 0.1rem;
      line-height: $size-p + 0.5rem;
    }

    h1 {
      font-size: $size-h1 - 0.1rem;
      line-height: $size-h1 + 0.5rem;
    }

    ul li,
    td,
    th {
      font-size: $size-li - 0.1rem;
      line-height: $size-li + 0.5rem;
    }
  }

  &.size-medium {
    p {
      font-size: $size-p;
      line-height: $size-p + 0.6rem;
    }

    h1 {
      font-size: $size-h1;
      line-height: $size-h1 + 0.6rem;
    }

    ul li,
    td,
    th {
      font-size: $size-li;
      line-height: $size-li + 0.6rem;
    }
  }

  &.size-large {
    p {
      font-size: $size-p + 0.1rem;
      line-height: $size-p + 0.7rem;
    }

    h1 {
      font-size: $size-h1 + 0.1rem;
      line-height: $size-h1 + 0.7rem;
    }

    ul li,
    td,
    th {
      font-size: $size-li + 0.1rem;
      line-height: $size-li + 0.7rem;
    }
  }

  &.size-xlarge {
    p {
      font-size: $size-p + 0.2rem;
      line-height: $size-p + 0.8rem;
    }

    h1 {
      font-size: $size-h1 + 0.2rem;
      line-height: $size-h1 + 0.8rem;
    }

    ul li,
    td,
    th {
      font-size: $size-li + 0.2rem;
      line-height: $size-li + 0.8rem;
    }
  }
}

@mixin font-size-mat-labels($size) {
  &.size-xsmall {

    mat-label,
    label {
      font-size: $size - 0.2rem;
      line-height: $size + 0.4rem;
    }
  }

  &.size-small {

    mat-label,
    label {
      font-size: $size - 0.1rem;
      line-height: $size + 0.5rem;
    }
  }

  &.size-medium {

    mat-label,
    label {
      font-size: $size;
      line-height: $size + 0.6rem;
    }
  }

  &.size-large {

    mat-label,
    label {
      font-size: $size + 0.1rem;
      line-height: $size + 0.7rem;
    }
  }

  &.size-xlarge {

    mat-label,
    label {
      font-size: $size + 0.2rem;
      line-height: $size + 0.8rem;
    }
  }
}

/* ******************* Fund Colors ******************* */

@mixin fund-color-risk($fund) {
  .fund-#{$fund}-risk {
    color: var(--ion-color-fund-#{$fund});
  }

  .fund-#{$fund}-background {
    background-color: var(--ion-color-fund-#{$fund});
  }
}

@mixin fund-background-color($fund) {
  .mat-accent.first-fund-#{$fund} .mat-slider-track-fill {
    background-color: var(--ion-color-fund-#{$fund});
  }

  .mat-accent.second-fund-#{$fund} .mat-slider-track-background {
    background-color: var(--ion-color-fund-#{$fund});
  }
}

/* ******************* Social network Colors ******************* */
@mixin social-network($name) {
  &.#{$name} {
    background-color: var(--ion-color-#{$name});
  }
}