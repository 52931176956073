/**
 * * Main PLV MAC Colors.
 * The nomenclature of the variables are defined by the name chosen in the design stage,
 * which are reflected in this figure:
 * https://www.figma.com/file/xqbOqXiL81yTZAuyLt7IMo/Modulo-Atenci%C3%B3n-Carterizada?node-id=432%3A6895&t=y2Bqyic5LJDEEGn0-0
 */

/**
 * Used by Ionic
 */
// $gray-30 is being used as ion-color-dark or color="dark"
$gray-30: #2C343C;
// $primary is being used as ion-color-primary or color="primary"
$primary: #C23B33;
// $orange is being used as ion-color-warning or color="warning"
$orange: #DF6744;
// $lightblue is being used as ion-color-light or color="light"
$lightblue: #E9F1F3;
// $red is being used as ion-color-secondary or color="secondary"
$red: #B74849;
// $bordeaux is being used as ion-color-danger or color="danger"
$bordeaux: #8E1230;
// $success is being used as ion-color-success or color="success"
$success: #3D915E;
// $obscure is being used as ion-color-tertiary or color="tertiary"
$obscure: #8E2A2A;

/**
 * General
 */
$white: #FFFFFF;
$gray-10: #F6F7F8;
$gray-20: #A6B1BD;
$gray-25: #607081;
$gray-50: #EDEDED;
$gray100: #B0B2B3;
$darkred: #6C0600;
$skybluedark: #6EA7BC;
$rosedark: #FFE0DF;
$lightred: #E08E8A;
$pastelgreen: #B9DAAF;
$pastelorange: #EDD3C8;
$salmon: #FFC4C6;
$pinkdark: #FF8B8E;
$light-reddish: #930800;
$black: #000000;
$yellow: #F7E249;
$tangerine: #F2B51D;
$redmond: #510300;

/**
 * * Main PLV MAC Theme.
 */
:root {
    --ion-color-white: #FFFFFF;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0, 0, 0;
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;

    --ion-color-gray-10: #F6F7F8;
    --ion-color-gray-10-rgb: 246, 247, 248;
    --ion-color-gray-10-contrast: #000000;
    --ion-color-gray-10-contrast-rgb: 0, 0, 0;
    --ion-color-gray-10-shade: #d8d9da;
    --ion-color-gray-10-tint: #f7f8f9;

    --ion-color-gray-20: #A6B1BD;
    --ion-color-gray-20-rgb: 166, 177, 189;
    --ion-color-gray-20-contrast: #000000;
    --ion-color-gray-20-contrast-rgb: 0, 0, 0;
    --ion-color-gray-20-shade: #929ca6;
    --ion-color-gray-20-tint: #afb9c4;

    --ion-color-gray-25: #607081;
    --ion-color-gray-25-rgb: 96, 112, 129;
    --ion-color-gray-25-contrast: #ffffff;
    --ion-color-gray-25-contrast-rgb: 255, 255, 255;
    --ion-color-gray-25-shade: #546372;
    --ion-color-gray-25-tint: #707e8e;

    --ion-color-gray-50: #EDEDED;
    --ion-color-gray-50-rgb: 237, 237, 237;
    --ion-color-gray-50-contrast: #000000;
    --ion-color-gray-50-contrast-rgb: 0, 0, 0;
    --ion-color-gray-50-shade: #d1d1d1;
    --ion-color-gray-50-tint: #efefef;

    --ion-color-gray100: #B0B2B3;
    --ion-color-gray100-rgb: 176, 178, 179;
    --ion-color-gray100-contrast: #000000;
    --ion-color-gray100-contrast-rgb: 0, 0, 0;
    --ion-color-gray100-shade: #9b9d9e;
    --ion-color-gray100-tint: #b8babb;

    --ion-color-darkred: #6C0600;
    --ion-color-darkred-rgb: 108, 6, 0;
    --ion-color-darkred-contrast: #ffffff;
    --ion-color-darkred-contrast-rgb: 255, 255, 255;
    --ion-color-darkred-shade: #5f0500;
    --ion-color-darkred-tint: #7b1f1a;

    --ion-color-skybluedark: #6EA7BC;
    --ion-color-skybluedark-rgb: 110, 167, 188;
    --ion-color-skybluedark-contrast: #000000;
    --ion-color-skybluedark-contrast-rgb: 0, 0, 0;
    --ion-color-skybluedark-shade: #6193a5;
    --ion-color-skybluedark-tint: #7db0c3;

    --ion-color-rosedark: #FFE0DF;
    --ion-color-rosedark-rgb: 255, 224, 223;
    --ion-color-rosedark-contrast: #000000;
    --ion-color-rosedark-contrast-rgb: 0, 0, 0;
    --ion-color-rosedark-shade: #e0c5c4;
    --ion-color-rosedark-tint: #ffe3e2;

    --ion-color-lightred: #E08E8A;
    --ion-color-lightred-rgb: 224, 142, 138;
    --ion-color-lightred-contrast: #000000;
    --ion-color-lightred-contrast-rgb: 0, 0, 0;
    --ion-color-lightred-shade: #c57d79;
    --ion-color-lightred-tint: #e39996;

    --ion-color-pastelgreen: #B9DAAF;
    --ion-color-pastelgreen-rgb: 185, 218, 175;
    --ion-color-pastelgreen-contrast: #000000;
    --ion-color-pastelgreen-contrast-rgb: 0, 0, 0;
    --ion-color-pastelgreen-shade: #a3c09a;
    --ion-color-pastelgreen-tint: #c0deb7;

    --ion-color-pastelorange: #EDD3C8;
    --ion-color-pastelorange-rgb: 237, 211, 200;
    --ion-color-pastelorange-contrast: #000000;
    --ion-color-pastelorange-contrast-rgb: 0, 0, 0;
    --ion-color-pastelorange-shade: #d1bab0;
    --ion-color-pastelorange-tint: #efd7ce;

    --ion-color-salmon: #FFC4C6;
    --ion-color-salmon-rgb: 255, 196, 198;
    --ion-color-salmon-contrast: #000000;
    --ion-color-salmon-contrast-rgb: 0, 0, 0;
    --ion-color-salmon-shade: #e0acae;
    --ion-color-salmon-tint: #ffcacc;

    --ion-color-pinkdark: #FF8B8E;
    --ion-color-pinkdark-rgb: 255, 139, 142;
    --ion-color-pinkdark-contrast: #000000;
    --ion-color-pinkdark-contrast-rgb: 0, 0, 0;
    --ion-color-pinkdark-shade: #e07a7d;
    --ion-color-pinkdark-tint: #ff9799;

    --ion-color-light-reddish: #930800;
    --ion-color-light-reddish-rgb: 147, 8, 0;
    --ion-color-light-reddish-contrast: #ffffff;
    --ion-color-light-reddish-contrast-rgb: 255, 255, 255;
    --ion-color-light-reddish-shade: #810700;
    --ion-color-light-reddish-tint: #9e211a;

    --ion-color-black: #000000;
    --ion-color-black-rgb: 0, 0, 0;
    --ion-color-black-contrast: #ffffff;
    --ion-color-black-contrast-rgb: 255, 255, 255;
    --ion-color-black-shade: #000000;
    --ion-color-black-tint: #1a1a1a;

    --ion-color-yellow: #F7E249;
    --ion-color-yellow-rgb: 247, 226, 73;
    --ion-color-yellow-contrast: #000000;
    --ion-color-yellow-contrast-rgb: 0, 0, 0;
    --ion-color-yellow-shade: #d9c740;
    --ion-color-yellow-tint: #f8e55b;

    --ion-color-tangerine: #F2B51D;
    --ion-color-tangerine-rgb: 242, 181, 29;
    --ion-color-tangerine-contrast: #000000;
    --ion-color-tangerine-contrast-rgb: 0, 0, 0;
    --ion-color-tangerine-shade: #d59f1a;
    --ion-color-tangerine-tint: #f3bc34;

    --ion-color-redmond: #510300;
    --ion-color-redmond-rgb: 81, 3, 0;
    --ion-color-redmond-contrast: #ffffff;
    --ion-color-redmond-contrast-rgb: 255, 255, 255;
    --ion-color-redmond-shade: #470300;
    --ion-color-redmond-tint: #621c1a;
}

.ion-color-white {
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-gray-10 {
    --ion-color-base: var(--ion-color-gray-10);
    --ion-color-base-rgb: var(--ion-color-gray-10-rgb);
    --ion-color-contrast: var(--ion-color-gray-10-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gray-10-contrast-rgb);
    --ion-color-shade: var(--ion-color-gray-10-shade);
    --ion-color-tint: var(--ion-color-gray-10-tint);
}

.ion-color-gray-20 {
    --ion-color-base: var(--ion-color-gray-20);
    --ion-color-base-rgb: var(--ion-color-gray-20-rgb);
    --ion-color-contrast: var(--ion-color-gray-20-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gray-20-contrast-rgb);
    --ion-color-shade: var(--ion-color-gray-20-shade);
    --ion-color-tint: var(--ion-color-gray-20-tint);
}

.ion-color-gray-25 {
    --ion-color-base: var(--ion-color-gray-25);
    --ion-color-base-rgb: var(--ion-color-gray-25-rgb);
    --ion-color-contrast: var(--ion-color-gray-25-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gray-25-contrast-rgb);
    --ion-color-shade: var(--ion-color-gray-25-shade);
    --ion-color-tint: var(--ion-color-gray-25-tint);
}

.ion-color-gray-50 {
    --ion-color-base: var(--ion-color-gray-50);
    --ion-color-base-rgb: var(--ion-color-gray-50-rgb);
    --ion-color-contrast: var(--ion-color-gray-50-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gray-50-contrast-rgb);
    --ion-color-shade: var(--ion-color-gray-50-shade);
    --ion-color-tint: var(--ion-color-gray-50-tint);
}

.ion-color-gray100 {
    --ion-color-base: var(--ion-color-gray100);
    --ion-color-base-rgb: var(--ion-color-gray100-rgb);
    --ion-color-contrast: var(--ion-color-gray100-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gray100-contrast-rgb);
    --ion-color-shade: var(--ion-color-gray100-shade);
    --ion-color-tint: var(--ion-color-gray100-tint);
}

.ion-color-darkred {
    --ion-color-base: var(--ion-color-darkred);
    --ion-color-base-rgb: var(--ion-color-darkred-rgb);
    --ion-color-contrast: var(--ion-color-darkred-contrast);
    --ion-color-contrast-rgb: var(--ion-color-darkred-contrast-rgb);
    --ion-color-shade: var(--ion-color-darkred-shade);
    --ion-color-tint: var(--ion-color-darkred-tint);
}

.ion-color-skybluedark {
    --ion-color-base: var(--ion-color-skybluedark);
    --ion-color-base-rgb: var(--ion-color-skybluedark-rgb);
    --ion-color-contrast: var(--ion-color-skybluedark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-skybluedark-contrast-rgb);
    --ion-color-shade: var(--ion-color-skybluedark-shade);
    --ion-color-tint: var(--ion-color-skybluedark-tint);
}

.ion-color-rosedark {
    --ion-color-base: var(--ion-color-rosedark);
    --ion-color-base-rgb: var(--ion-color-rosedark-rgb);
    --ion-color-contrast: var(--ion-color-rosedark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-rosedark-contrast-rgb);
    --ion-color-shade: var(--ion-color-rosedark-shade);
    --ion-color-tint: var(--ion-color-rosedark-tint);
}

.ion-color-lightred {
    --ion-color-base: var(--ion-color-lightred);
    --ion-color-base-rgb: var(--ion-color-lightred-rgb);
    --ion-color-contrast: var(--ion-color-lightred-contrast);
    --ion-color-contrast-rgb: var(--ion-color-lightred-contrast-rgb);
    --ion-color-shade: var(--ion-color-lightred-shade);
    --ion-color-tint: var(--ion-color-lightred-tint);
}

.ion-color-pastelgreen {
    --ion-color-base: var(--ion-color-pastelgreen);
    --ion-color-base-rgb: var(--ion-color-pastelgreen-rgb);
    --ion-color-contrast: var(--ion-color-pastelgreen-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pastelgreen-contrast-rgb);
    --ion-color-shade: var(--ion-color-pastelgreen-shade);
    --ion-color-tint: var(--ion-color-pastelgreen-tint);
}

.ion-color-pastelorange {
    --ion-color-base: var(--ion-color-pastelorange);
    --ion-color-base-rgb: var(--ion-color-pastelorange-rgb);
    --ion-color-contrast: var(--ion-color-pastelorange-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pastelorange-contrast-rgb);
    --ion-color-shade: var(--ion-color-pastelorange-shade);
    --ion-color-tint: var(--ion-color-pastelorange-tint);
}

.ion-color-salmon {
    --ion-color-base: var(--ion-color-salmon);
    --ion-color-base-rgb: var(--ion-color-salmon-rgb);
    --ion-color-contrast: var(--ion-color-salmon-contrast);
    --ion-color-contrast-rgb: var(--ion-color-salmon-contrast-rgb);
    --ion-color-shade: var(--ion-color-salmon-shade);
    --ion-color-tint: var(--ion-color-salmon-tint);
}

.ion-color-pinkdark {
    --ion-color-base: var(--ion-color-pinkdark);
    --ion-color-base-rgb: var(--ion-color-pinkdark-rgb);
    --ion-color-contrast: var(--ion-color-pinkdark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pinkdark-contrast-rgb);
    --ion-color-shade: var(--ion-color-pinkdark-shade);
    --ion-color-tint: var(--ion-color-pinkdark-tint);
}

.ion-color-light-reddish {
    --ion-color-base: var(--ion-color-light-reddish);
    --ion-color-base-rgb: var(--ion-color-light-reddish-rgb);
    --ion-color-contrast: var(--ion-color-light-reddish-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-reddish-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-reddish-shade);
    --ion-color-tint: var(--ion-color-light-reddish-tint);
}

.ion-color-black {
    --ion-color-base: var(--ion-color-black);
    --ion-color-base-rgb: var(--ion-color-black-rgb);
    --ion-color-contrast: var(--ion-color-black-contrast);
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
    --ion-color-shade: var(--ion-color-black-shade);
    --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-yellow {
    --ion-color-base: var(--ion-color-yellow);
    --ion-color-base-rgb: var(--ion-color-yellow-rgb);
    --ion-color-contrast: var(--ion-color-yellow-contrast);
    --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
    --ion-color-shade: var(--ion-color-yellow-shade);
    --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-tangerine {
    --ion-color-base: var(--ion-color-tangerine);
    --ion-color-base-rgb: var(--ion-color-tangerine-rgb);
    --ion-color-contrast: var(--ion-color-tangerine-contrast);
    --ion-color-contrast-rgb: var(--ion-color-tangerine-contrast-rgb);
    --ion-color-shade: var(--ion-color-tangerine-shade);
    --ion-color-tint: var(--ion-color-tangerine-tint);
}

.ion-color-redmond {
    --ion-color-base: var(--ion-color-redmond);
    --ion-color-base-rgb: var(--ion-color-redmond-rgb);
    --ion-color-contrast: var(--ion-color-redmond-contrast);
    --ion-color-contrast-rgb: var(--ion-color-redmond-contrast-rgb);
    --ion-color-shade: var(--ion-color-redmond-shade);
    --ion-color-tint: var(--ion-color-redmond-tint);
}