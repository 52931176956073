:root {
  --icon-up-down: url(../assets/svg/icons/arrow-up-arrow-down.svg);
  --icon-check: url(../assets/svg/icons/check.svg);
  --icon-chevron-down-filled: url(../assets/svg/icons/chevron-down-filled.svg);
  --icon-chevron-down: url(../assets/svg/icons/chevron-down.svg);
  --icon-chevron-left-filled: url(../assets/svg/icons/chevron-left-filled.svg);
  --icon-chevron-left: url(../assets/svg/icons/chevron-left.svg);
  --icon-chevron-right-filled: url(../assets/svg/icons/chevron-right-filled.svg);
  --icon-chevron-right: url(../assets/svg/icons/chevron-right.svg);
  --icon-chevron-up-filled: url(../assets/svg/icons/chevron-up-filled.svg);
  --icon-chevron-up: url(../assets/svg/icons/chevron-up.svg);
  --icon-chevrons-right: url(../assets/svg/icons/chevrons-right.svg);
  --icon-circle-check: url(../assets/svg/icons/circle-check.svg);
  --icon-circle-info: url(../assets/svg/icons/circle-info.svg);
  --icon-circle-menu: url(../assets/svg/icons/circle-menu.svg);
  --icon-circle-user-red: url(../assets/svg/icons/circle-user-red.svg);
  --icon-circle-user-white: url(../assets/svg/icons/circle-user-white.svg);
  --icon-circle-xmark: url(../assets/svg/icons/circle-xmark.svg);
  --icon-circle: url(../assets/svg/icons/circle.svg);
  --icon-exclamation: url(../assets/svg/icons/exclamation.svg);
  --icon-eye-filled: url(../assets/svg/icons/eye-filled.svg);
  --icon-eye-screened-filled: url(../assets/svg/icons/eye-screened-filled.svg);
  --icon-eye: url(../assets/svg/icons/eye.svg);
  --icon-gear-red: url(../assets/svg/icons/gear-red.svg);
  --icon-gear-white: url(../assets/svg/icons/gear-white.svg);
  --icon-gear: url(../assets/svg/icons/gear.svg);
  --icon-house-red: url(../assets/svg/icons/house-red.svg);
  --icon-house-white: url(../assets/svg/icons/house-white.svg);
  --icon-house: url(../assets/svg/icons/house.svg);
  --icon-info: url(../assets/svg/icons/info.svg);
  --icon-log-out-white: url(../assets/svg/icons/log-out-white.svg);
  --icon-magnifying-glass-gray: url(../assets/svg/icons/magnifying-glass-gray.svg);
  --icon-pen: url(../assets/svg/icons/pen.svg);
  --icon-pen-gray: url(../assets/svg/icons/pen-gray.svg);
  --icon-people-all-white: url(../assets/svg/icons/people-all-white.svg);
  --icon-people-all: url(../assets/svg/icons/people-all.svg);
  --icon-people-meet-red: url(../assets/svg/icons/people-meet-red.svg);
  --icon-people-meet-white: url(../assets/svg/icons/people-meet-white.svg);
  --icon-people-supervisors: url(../assets/svg/icons/people-supervisors.svg);
  --icon-person-administrator: url(../assets/svg/icons/person-administrator.svg);
  --icon-person-executive: url(../assets/svg/icons/person-executive.svg);
  --icon-person-retired-red: url(../assets/svg/icons/person-retired-red.svg);
  --icon-person-retired-white: url(../assets/svg/icons/person-retired-white.svg);
  --icon-person-retired: url(../assets/svg/icons/person-retired.svg);
  --icon-square-pen: url(../assets/svg/icons/square-pen.svg);
  --icon-tags-red: url(../assets/svg/icons/tags-red.svg);
  --icon-tags-white: url(../assets/svg/icons/tags-white.svg);
  --icon-trash: url(../assets/svg/icons/trash.svg);
  --icon-trash-gray: url(../assets/svg/icons/trash-gray.svg);
  --icon-triangle-exclamation-filled-red: url(../assets/svg/icons/triangle-exclamation-filled-red.svg);
  --icon-triangle-exclamation: url(../assets/svg/icons/triangle-exclamation.svg);
  --icon-user-check-white: url(../assets/svg/icons/user-check-white.svg);
  --icon-user-pen: url(../assets/svg/icons/user-pen.svg);
  --icon-user-plus-white: url(../assets/svg/icons/user-plus-white.svg);
  --icon-xmark: url(../assets/svg/icons/xmark.svg);
}

.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  min-width: 14px;
  min-height: 14px;
}

.i-up-down {
  @extend .icon;
  background-image: var(--icon-up-down);
}

.i-check {
  @extend .icon;
  background-image: var(--icon-check);
}

.i-chevron-down-filled {
  @extend .icon;
  background-image: var(--icon-chevron-down-filled);
}

.i-chevron-down {
  @extend .icon;
  background-image: var(--icon-chevron-down);
}

.i-chevron-left-filled {
  @extend .icon;
  background-image: var(--icon-chevron-left-filled);
}

.i-chevron-left {
  @extend .icon;
  background-image: var(--icon-chevron-left);
}

.i-chevron-right-filled {
  @extend .icon;
  background-image: var(--icon-chevron-right-filled);
}

.i-chevron-right {
  @extend .icon;
  background-image: var(--icon-chevron-right);
}

.i-icon-chevron-up-filled {
  @extend .icon;
  background-image: var(--icon-icon-chevron-up-filled);
}

.i-icon-chevron-up {
  @extend .icon;
  background-image: var(--icon-icon-chevron-up);
}

.i-chevrons-right {
  @extend .icon;
  background-image: var(--icon-chevrons-right);
}

.i-circle-check {
  @extend .icon;
  background-image: var(--icon-circle-check);
}

.i-circle-info {
  @extend .icon;
  background-image: var(--icon-circle-info);
}

.i-circle-menu {
  @extend .icon;
  background-image: var(--icon-circle-menu);
}

.i-circle-user-red {
  @extend .icon;
  background-image: var(--icon-circle-user-red);
}

.i-circle-user-white {
  @extend .icon;
  background-image: var(--icon-circle-user-white);
}

.i-circle-xmark {
  @extend .icon;
  background-image: var(--icon-circle-xmark);
}

.i-circle {
  @extend .icon;
  background-image: var(--icon-circle);
}

.i-exclamation {
  @extend .icon;
  background-image: var(--icon-exclamation);
}

.i-eye-filled {
  @extend .icon;
  background-image: var(--icon-eye-filled);
}

.i-eye-screened-filled {
  @extend .icon;
  background-image: var(--icon-eye-screened-filled);
}

.i-eye {
  @extend .icon;
  background-image: var(--icon-eye);
}

.i-gear-red {
  @extend .icon;
  background-image: var(--icon-gear-red);
}

.i-gear-white {
  @extend .icon;
  background-image: var(--icon-gear-white);
}

.i-gear {
  @extend .icon;
  background-image: var(--icon-gear);
}

.i-house-red {
  @extend .icon;
  background-image: var(--icon-house-red);
}

.i-house-white {
  @extend .icon;
  background-image: var(--icon-house-white);
}

.i-house {
  @extend .icon;
  background-image: var(--icon-house);
}

.i-info {
  @extend .icon;
  background-image: var(--icon-info);
}

.i-log-out-white {
  @extend .icon;
  background-image: var(--icon-log-out-white);
}

.i-magnifying-glass-gray {
  @extend .icon;
  background-image: var(--icon-magnifying-glass-gray);
}

.i-pen {
  @extend .icon;
  background-image: var(--icon-pen);
}

.i-pen-gray {
  @extend .icon;
  background-image: var(--icon-pen-gray);
}

.i-people-all-white {
  @extend .icon;
  background-image: var(--icon-people-all-white);
}

.i-people-all {
  @extend .icon;
  background-image: var(--icon-people-all);
}

.i-people-meet-red {
  @extend .icon;
  background-image: var(--icon-people-meet-red);
}

.i-people-meet-white {
  @extend .icon;
  background-image: var(--icon-people-meet-white);
}

.i-people-supervisors {
  @extend .icon;
  background-image: var(--icon-people-supervisors);
}

.i-person-administrator {
  @extend .icon;
  background-image: var(--icon-person-administrator);
}

.i-person-executive {
  @extend .icon;
  background-image: var(--icon-person-executive);
}

.i-person-retired-red {
  @extend .icon;
  background-image: var(--icon-person-retired-red);
}

.i-person-retired-white {
  @extend .icon;
  background-image: var(--icon-person-retired-white);
}

.i-person-retired {
  @extend .icon;
  background-image: var(--icon-person-retired);
}

.i-square-pen {
  @extend .icon;
  background-image: var(--icon-square-pen);
}

.i-tags-red {
  @extend .icon;
  background-image: var(--icon-tags-red);
}

.i-tags-white {
  @extend .icon;
  background-image: var(--icon-tags-white);
}

.i-trash {
  @extend .icon;
  background-image: var(--icon-trash);
}

.i-trash-gray {
  @extend .icon;
  background-image: var(--icon-trash-gray);
}

.i-triangle-exclamation-filled-red {
  @extend .icon;
  background-image: var(--icon-triangle-exclamation-filled-red);
}

.i-triangle-exclamation {
  @extend .icon;
  background-image: var(--icon-triangle-exclamation);
}

.i-user-check-white {
  @extend .icon;
  background-image: var(--icon-user-check-white);
}

.i-user-pen {
  @extend .icon;
  background-image: var(--icon-user-pen);
}

.i-user-plus-white {
  @extend .icon;
  background-image: var(--icon-user-plus-white);
}

.i-xmark {
  @extend .icon;
  background-image: var(--icon-xmark);
}