:root {
  --theadColor: #fff;
}

body {
  font-family: var(--ion-font-family-regular);
}

thead {
  background-color: var(--theadColor);

}

thead>tr,
thead>tr>th {
  background-color: transparent;
  color: #929292;
  font-weight: normal;
  text-align: center;
}

.dataTables_wrapper>div {
  margin: 5px;
}

table.dataTable.display tbody tr.even>.sorting_1,
table.dataTable.order-column.stripe tbody tr.even>.sorting_1,
table.dataTable.display tbody tr.even,
table.dataTable.display tbody tr.odd>.sorting_1,
table.dataTable.order-column.stripe tbody tr.odd>.sorting_1,
table.dataTable.display tbody tr.odd {
  background-color: #ffffff;
}

table.dataTable thead th {
  position: relative;
  background-image: none !important;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  position: absolute;
  top: 12px;
  right: 8px;
  display: block;
}

table.dataTable thead th.sorting:after {
  content: "\f0dc";
  font-size: 0.8em;
  padding-top: 0.12em;
}

table.dataTable thead th.sorting_asc:after {
  content: "\f0de";
}

table.dataTable thead th.sorting_desc:after {
  content: "\f0dd";
}

table.dataTable.display tbody tr:hover>.sorting_1,
table.dataTable.order-column.hover tbody tr:hover>.sorting_1 {
  background-color: #f2f2f2 !important;
  color: #2C343C;
}

tbody tr:hover {
  background-color: #EDEDED !important;
  color: #000;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: none !important;
  border-radius: 25px;
  background-color: #fff !important;
  color: #2C343C !important
}

.paginate_button.current:hover {
  background: none !important;
  border-radius: 25px;
  background-color: #C23B33 !important;
  color: #fff !important
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {

  border: 1px solid #979797;
  background: none !important;
  border-radius: 25px !important;
  background-color: #C23B33 !important;
  color: #fff !important;
}

.custom-cell {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  width: 100px;
  height: 23px;
  border-radius: 5px;
  cursor: pointer;


  &.salmon {
    color: #B74849;
    background: #FFC4C6;
  }

  &.pastelgreen {
    color: #3D915E;
    background: #B9DAAF;
  }

  &.pastelorange {
    color: #DF6744;
    background: #EDD3C8;
  }

  &.lightblue {
    color: #6EA7BC;
    background: #E9F1F3;
  }
}

.dropdown {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;

  .menu-icon {
    background-size: 14px 14px;
    background-position: center;
    width: 11px;
    height: 11px;
  }
}

.dropdown-content {
  cursor: pointer;
  display: none;
  position: absolute;
  background-color: #F6F7F8;
  min-height: 40px;
  min-width: 95px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  padding: 0px 5px;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;

  .dropdown-item {
    color: #A6B1BD;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    margin: 4px 4px;

    .item-icon {
      background-size: 15px 15px;
      background-position: center;
      width: 12px;
      height: 12px;
      filter: invert(73%) sepia(10%) saturate(324%) hue-rotate(171deg) brightness(95%) contrast(90%);
    }
  }

  .dropdown-item:hover {
    color: #C23B33;

    .item-icon {
      filter: invert(32%) sepia(31%) saturate(1500%) hue-rotate(319deg) brightness(103%) contrast(105%);
    }
  }
}

.dropdown:hover .dropdown-content {
  display: flex;
}